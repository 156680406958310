<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-form ref="form" :model="formData" :rules="rules" label-width="80px">
      <el-form-item label="姓名" prop="username">
        <el-input style="width: 400px" clearable size="small" placeholder="请输入" v-model="formData.username"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="mobile">
        <el-input style="width: 400px" clearable size="small" placeholder="请输入" v-model="formData.mobile"></el-input>
      </el-form-item>

      <el-form-item label="身份" >
        <el-radio-group v-model="formData.level">
          <el-radio :label="1">指挥中心</el-radio>
          <el-radio :label="10">片区委员会</el-radio>
          <el-radio :label="20">镇司法所</el-radio>
          <el-radio :label="30">村调解员</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item v-show="formData.level == 30 || formData.level == 20" label="区域" prop="area_ids">
        <SelectArea v-model="formData.area_ids" :level="formData.level"></SelectArea>
      </el-form-item>

      <el-form-item v-show="formData.level == 10" label="片区" prop="district_id">
        <SelectDistrict v-model="formData.district_id"></SelectDistrict>
      </el-form-item>

      <el-form-item label="">
        <el-button type="" size="small" @click="$router.back()">取 消</el-button>
        <el-button type="primary" size="small" @click="save">保 存</el-button>
      </el-form-item>
    </el-form>
    <div style="height: 50px"></div>
  </div>
</template>

<script>
import { addAPI, editAPI, getDetailAPI } from './api'
import { dict_law_people_level } from '@/enum/dict.js'
import SelectDistrict from '@/views/components/select-district.vue'
import SelectArea from '@/views/components/select-area-all.vue'
export default {
  name: 'AddOrEdit',
  components: {
    SelectDistrict,
    SelectArea
  },
  data() {
    var checkDistrict = (rule, value, callback) => {
      if (this.formData.level === 10 && !value) {
        callback(new Error('片区不能为空'))
      } else {
        callback()
      }
    }
    var checkArea = (rule, value, callback) => {
      if (this.formData.level === 20 || this.formData.level === 30) {
        if (value.length < 1) {
          callback(new Error('区域不能为空'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    return {
      rules: {
        username: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
        mobile: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
        // level: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
        district_id: [{ validator: checkDistrict, trigger: 'blur' }],
        area_ids: [{ validator: checkArea, trigger: 'blur' }]
      },
      formData: {
        username: '',
        area_ids: [],
        mobile: '',
        level: undefined,
        district_id: ''
      },
      dict_law_people_level
    }
  },
  watch: {
    'formData.level': {
      handler(newV, oldV) {
        this.formData.area_ids = []
        this.formData.district_id = ''
      }
    }
  },
  mounted() {
    if (this.$route.params.id) this.getDetail()
  },

  methods: {
    save() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.formData.id) {
            editAPI(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.$router.back()
            })
          } else {
            addAPI(this.formData).then(() => {
              this.$message.success('添加成功')
              this.$router.back()
            })
          }
        }
      })
    },
    async getDetail() {
      this.formData = await getDetailAPI(this.$route.params.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
}
</style>
