<template>
  <el-cascader
    :style="{ width: width }"
    ref="tree"
    size="small"
    :options="list"
    :show-all-levels="false"
    :props="cascProps"
    filterable
    clearable
    placeholder="请选择"
    v-model="selectValue"
    @change="handleChange"
  ></el-cascader>
</template>

<script>
import { getAreaTree } from './api'
export default {
  name: 'SelectAreaAll',

  props: {
    value: {
      type: Array,
      default: () => []
    },
    width: {
      type: String,
      default: '400px'
    },
    level: {
      type: [Number, String],
      default: ''
    }
  },

  computed: {
    selectValue: {
      get() {
        return this.value
      },

      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    handle_area: {
      deep: true,
      handler(newV, oldV) {
        this.$emit('update:area_obj', newV)
      }
    },
    level: {
      handler(newV, oldV) {
        if (newV == 20 || newV == 30) this.getDeptTree()
      }
    },
    area_obj: {
      handler(newV, oldV) {
        console.log(newV, this.default_ids)
      }
    }
  },
  data() {
    return {
      list: [],
      cascProps: {
        label: 'name',
        value: 'id',
        checkStrictly: true,
        expandTrigger: 'hover',
        emitPath: true,
        multiple: false,
        leaf: 'leaf'
      },
      ids: []
    }
  },

  created() {
    this.getDeptTree()
  },

  methods: {
    async getDeptTree() {
      let res = await getAreaTree()
      if (this.level == 20 || this.level == 30) {
        this.list = this.filterList(res)
        this.list = this.setLeaf(this.list)
        return
      }
      this.list = this.setLeaf(res)
      this.list.find(item => item.pid == 0).disabled = true
    },

    setLeaf(list) {
      return list.map(item => {
        if (item.children && item.children.length > 0) item.children = this.setLeaf(item.children)
        else {
          item.leaf = true
          delete item.children
        }
        return item
      })
    },

    filterList(list) {
      if (this.level == 20) {
        // 只能选镇
        list.forEach(item => {
          if (item.level !== 3) {
            item.disabled = true
          }
          if (item.children && item.children.length > 0) {
            item.children = this.filterList(item.children)
          }
        })
        return list
      } else if (this.level == 30) {
        // 只能选村
        list.forEach(item => {
          if (item.level !== 4) {
            item.disabled = true
          }
          if (item.children && item.children.length > 0) {
            item.children = this.filterList(item.children)
          }
        })
        return list
      }

      // if (this.level !== 20 || this.level !== 30) return list
      // return list.filter(item => {
      //   if (item.children && item.children.length > 0) {
      //     item.children = this.filterList(item.children)
      //   }
      //   if (item.level !== this.level) {
      //     return true
      //   }
      // })
    },
    handleChange(node) {
      // console.log(this.$refs.tree.getCheckedNodes(true))
    }
  }
}
</script>

<style></style>
