import { http } from '@/http/axios.js'
// 列表
export function getListAPI(params) {
  return http({
    url: '/company/law-people/list',
    method: 'get',
    params
  })
}
// 详情
export function getDetailAPI(id) {
  return http({
    url: '/company/law-people/detail',
    method: 'get',
    params: { id }
  })
}
// add
export function addAPI(data) {
  return http({
    url: '/company/law-people/add',
    method: 'post',
    data
  })
}
// edit
export function editAPI(data) {
  return http({
    url: '/company/law-people/edit',
    method: 'post',
    data
  })
}
// del
export function delAPI(id) {
  return http({
    url: '/company/law-people/del',
    method: 'post',
    data: { id }
  })
}
